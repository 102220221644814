import { Controller } from '@hotwired/stimulus';
import { findSimilarColors } from '@/utils/color';

export default class extends Controller {
  static targets = ['button', 'outlineButton', 'hover'];

  static values = {
    controllerName: String,
    actionName: String,
    color: {
      type: String,
    },
  };

  buttonTargetConnected(element) {
    $(element).css({
      borderColor: this.colorValue,
      backgroundColor: this.colorValue,
    });
  }

  outlineButtonTargetConnected(element) {
    $(element).css({
      borderColor: this.colorValue,
      color: this.colorValue,
    });
  }

  hoverTargetConnected(element) {
    if (
      this.controllerNameValue !== 'welcomes' &&
      this.actionNameValue !== 'index'
    ) {
      $(element).hover(
        ({ currentTarget }) => {
          $(currentTarget).css({
            borderColor: this.colorValue,
            backgroundColor: findSimilarColors(this.colorValue),
          });
        },
        ({ currentTarget }) => {
          $(currentTarget).css({
            backgroundColor: 'white',
            borderColor: '#e6e6e6',
          });
        },
      );
    }
  }
}
