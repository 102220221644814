import { Controller } from '@hotwired/stimulus';
import Calendar from 'color-calendar';
import moment from 'moment';

import 'color-calendar/dist/css/theme-glass.css';
import 'color-calendar/dist/css/theme-basic.css';

export default class extends Controller {
  static targets = ['form', 'calendar', 'date', 'note'];

  static values = {
    color: { type: String },
    appointmentId: { type: String },
  };

  serviceSubmit(event) {
    event.preventDefault();

    $(event.currentTarget)
      .find("input[name='booking[service_id]']")
      .prop('checked', true);

    this.submitForm();
  }

  timeSlotSubmit(event) {
    event.preventDefault();

    const timeSlotBtn = $('.time-slot-btn');
    const step = this.formTarget.dataset.step;
    const currentTarget = $(event.currentTarget);
    const time = currentTarget.find("input[name='booking[start_date]']")[0]
      .value;

    if (this.isTimePassed(time)) {
      $('#timeline_error').html(
        "<span class='invalid-feedback'>You can’t choose a time in the past</span>",
      );
      return;
    } else {
      $('#timeline_error').html('');
      timeSlotBtn.removeClass('active');
      currentTarget.addClass('active');
      currentTarget
        .find("input[name='booking[start_date]']")
        .prop('checked', true);

      if (step == 'choose_date') this.submitForm();
    }
  }

  isTimePassed(targetTime) {
    const today = moment();
    const selectedDate = moment($(this.dateTarget).val(), 'DD/MM/YYYY');

    if (selectedDate.isAfter(today)) {
      return false;
    }

    const targetDate = new Date(`${new Date().toDateString()} ${targetTime}`);
    const currentTime = new Date();

    return currentTime >= targetDate;
  }

  inputCustomerSubmit(event) {
    event.preventDefault();

    $(event.currentTarget)
      .find("input[name='booking[payment_gateway]']")
      .prop('checked', true);

    this.submitForm();
  }

  getPastDates(date) {
    const pastDates = [];
    let today = new Date();

    if (date > today) {
      return pastDates;
    }
    const year = today.getFullYear();
    const month = today.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month, today.getDate() - 1);

    while (startDate <= endDate) {
      pastDates.push(startDate.getDate());

      startDate.setDate(startDate.getDate() + 1);
    }

    return pastDates;
  }

  calendarTargetConnected(element) {
    const {
      dataset: { date },
    } = element;

    this.currentDate = new Date(date);
    this.timeSlotBtn = $('.time-slot-btn');
    this.currentTime = $('.time-slot-btn.active');

    this.calendar = new Calendar({
      id: '#calendar',
      theme: 'basic',
      primaryColor: '#01111D',
      fontFamilyHeader: 'Helvetica Neue',
      weekdayType: 'short',
      border: 'none',
      dropShadow: 'none',
      calendarSize: 'large',
      disableMonthYearPickers: true,
      dateChanged: (date) => {
        $('.calendar__day.calendar__day-active').each((_, element) => {
          const el = $(element);
          const dateCurrent = el.find('.calendar__day-text').html();

          if (this.getPastDates().includes(parseInt(dateCurrent))) {
            el.addClass('calendar__day-other calendar__day-past');
            el.removeClass('calendar__day-active calendar__day-no-event');
          }
        });

        const today = moment().startOf('day');
        const selectedDate = moment(date).startOf('day');

        if (selectedDate.isBefore(today)) {
          this.calendar.setDate(today);
          return;
        }

        const calendarDate = moment(date).format('DD/MM/YYYY');

        $(this.dateTarget).val(calendarDate);
        this.applyWorkingHours(calendarDate);
      },
    });

    this.calendar.setDate(this.currentDate);
  }

  submitForm() {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch('/bookings/require_unique_booking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        id: this.appointmentIdValue,
      }),
    })
      .then((response) => response)
      .then((response) => {
        if (response.status == 200) $(this.formTarget).submit();
        if (response.status == 422)
          document.getElementById('unique-modal').open = true;
      });
  }

  countChar() {
    const target = this.noteTarget;
    const numberNote = target
      .closest('.note-container')
      .querySelector('.number-note');
    numberNote.innerHTML = `${target.value.length}/100`;

    if (target.value.length > 100) {
      $(target).parsley().validate();
    }
  }

  applyWorkingHours(calendarDate) {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch('/bookings/change_date_on_calendar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({
        date: calendarDate,
        booking_id: this.appointmentIdValue,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        const { working_hours } = response;

        this.timeSlotBtn.attr('disabled', false);
        this.timeSlotBtn.removeClass('active');
        this.timeSlotBtn.find('input').prop('checked', false);

        const currentDate = moment(this.currentDate).format('DD/MM/YYYY');

        if (calendarDate == currentDate) {
          this.currentTime.addClass('active');
          this.currentTime.find('input').prop('checked', true);
        }

        working_hours.forEach((workingHour) => {
          const id = workingHour.replace(':', '-');
          const time = $(`#${id}`).closest('label');

          if (!time.hasClass('active')) {
            time.attr('disabled', true);
          }
        });
      });
  }

  toggleSearch(element) {
    $(element.currentTarget).hide();
    $('.search-container').toggle();
  }
}
